<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path class="a" d="M6.734,13.982A4.5,4.5,0,1,1,12,7.271" />

        <polyline class="a" points="9.744 9.745 8.245 9.745 8.245 8.246" />

        <path
            class="a"
            d="M8.995,20.238H2.249a1.5,1.5,0,0,1-1.5-1.5V2.249a1.5,1.5,0,0,1,1.5-1.5H12.871a1.5,1.5,0,0,1,1.06.439l2.87,2.87a1.5,1.5,0,0,1,.439,1.06V6.747"
        />

        <circle class="a" cx="15.755" cy="15.755" r="5.247" />

        <line class="a" x1="23.25" y1="23.25" x2="19.465" y2="19.465" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'TimeClockFileSearch',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
