<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        :fill-color="color"
        @click="$emit('click')"
    >
        <line class="a" x1="17.249" y1="14.999" x2="19.499" y2="14.999" />

        <line class="a" x1="15.749" y1="10.499" x2="19.499" y2="10.499" />

        <line class="a" x1="14.249" y1="19.499" x2="19.499" y2="19.499" />

        <circle class="a" cx="8.999" cy="14.249" r="4.5" />

        <polyline class="a" points="5.294 16.718 8.999 14.249 8.999 9.749" />

        <path
            class="a"
            d="M22.809,5.559a1.5,1.5,0,0,1,.44,1.061V21A2.251,2.251,0,0,1,21,23.249H3A2.25,2.25,0,0,1,.749,21V3A2.25,2.25,0,0,1,3,.749H17.377a1.5,1.5,0,0,1,1.061.439Z"
        />

        <path class="a" d="M17.249.749v4.5a1.5,1.5,0,0,0,1.5,1.5h4.5" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'FileIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
