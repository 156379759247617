<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        :fill-color="color"
        @click="$emit('click')"
    >
        <path
            class="a"
            d="M22.19,1.81a3.639,3.639,0,0,0-5.17.035l-14.5,14.5L.75,23.25l6.905-1.771,14.5-14.5A3.637,3.637,0,0,0,22.19,1.81Z"
        />

        <line class="a" x1="16.606" y1="2.26" x2="21.74" y2="7.394" />

        <line class="a" x1="2.521" y1="16.344" x2="7.66" y2="21.474" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'PencilIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
