export default {
    data() {
        return {
            socketHandlers: {},
        }
    },
    mounted() {
        Object.keys(this.$options?.sockets ?? {}).forEach(event => {
            this.socketHandlers[event] = this.$options.sockets[event].bind(this)
            this.$socket?.on(event, this.socketHandlers[event])
        })
    },
    beforeDestroy() {
        Object.keys(this.socketHandlers).forEach(event => {
            this.$socket?.off(event, this.socketHandlers[event])
        })
    },
}
