<template>
    <div v-if="location">
        <ListItem class="u-bb">
            <div class="l-full-width l-inline l-gap-2 l-center-v">
                <PinLocationIcon width="24" height="24" />
                <div class="title-container">
                    <div class="l-inline l-gap-1">
                        <h3>{{ location.name }}</h3>

                        <div
                            v-if="userHasEditRights"
                            class="l-inline l-center-v l-gap-2"
                        >
                            <div>
                                <router-link
                                    :to="
                                        '/map/location/' + location.id + '/edit'
                                    "
                                >
                                    <IconButton>
                                        <PencilIcon
                                            :name="$t('editLocation')"
                                        />
                                    </IconButton>
                                </router-link>
                            </div>

                            <div>
                                <IconButton @click="handleLocationDelete">
                                    <TrashBinIcon
                                        :name="$t('deleteLocation')"
                                    />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    <br />
                    <p>{{ location.description }}</p>
                </div>
            </div>
        </ListItem>

        <AssetList
            :assets="[...assets, ...assetsCurrent]"
            :asset-detail-link-base="assetDetailLinkBase"
        />

        <div v-if="hasActivityAccess" class="u-bb">
            <router-link
                :to="{
                    name: 'locationAnimalActivityChart',
                    params: { id: id },
                }"
            >
                <ListItem>
                    <div class="l-inline l-gap-2">
                        <ActivityIcon width="24" height="24" />

                        <div class="l-stack l-gap-1 l-center">
                            <h3>{{ $t('showActivity') }}</h3>
                        </div>
                    </div>

                    <p><i class="arrow right" /></p>
                </ListItem>
            </router-link>
        </div>

        <AssetDocuments
            class="u-bb"
            :location="id"
            :show-edit-actions="userHasEditRights"
        />

        <div v-if="isSuperuser" class="u-bb">
            <div class="l-padded u-bb">{{ $t('diagnostics') }}</div>

            <router-link
                :to="{
                    name: 'locationConnectionHeatmap',
                    params: { locationId: id },
                }"
            >
                <ListItem>
                    <div class="l-inline l-gap-2">
                        <HeatmapIcon width="24" height="24" />

                        <div class="l-stack l-gap-1 l-center">
                            <h3>{{ $t('connectionHeatmap') }}</h3>
                        </div>
                    </div>

                    <p><i class="arrow right" /></p>
                </ListItem>
            </router-link>

            <router-link
                v-if="hasLocationHistoryAccess"
                :to="{ name: 'locationHistoryChart' }"
            >
                <ListItem>
                    <div class="l-inline l-gap-2">
                        <TimeClockFileSearch width="24" height="24" />

                        <div class="l-stack l-gap-1 l-center">
                            <h3>
                                {{ $t('router.locationHistoryChart') }}
                            </h3>
                        </div>
                    </div>

                    <p><i class="arrow right" /></p>
                </ListItem>
            </router-link>
        </div>

        <transition name="slide-from-right">
            <div v-if="isChartViewActive" class="detached-panel">
                <div
                    class="l-inline l-center-v l-spread l-padded u-bb detached-panel-header"
                >
                    {{ detachedPanelTitle }}

                    <router-link to=".">
                        <IconButton @click="$emit('expand')">
                            <RemoveIcon width="24" height="24" color="black" />
                        </IconButton>
                    </router-link>
                </div>

                <div class="detached-panel-body">
                    <router-view />
                </div>
            </div>
        </transition>

        <VDialog />
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

import { httpHelper } from '@/utils'
import { commonService } from '@/service/store.service'
import ActivityIcon from '../icons/ActivityIcon'
import AssetDocuments from '../AssetDocuments'
import AssetList from '../AssetList'
import CustomSocketHelper from '@/mixins/CustomSocketHelper'
import HeatmapIcon from '../icons/HeatmapIcon'
import IconButton from '../IconButton'
import ListItem from '../ListItem'
import PencilIcon from '../icons/PencilIcon'
import PinLocationIcon from '../icons/PinLocationIcon'
import RemoveIcon from '../icons/RemoveIcon'
import TimeClockFileSearch from '../icons/TimeClockFileSearch'
import TrashBinIcon from '../icons/TrashBinIcon'

export default {
    name: 'LocationDetailView',
    components: {
        ActivityIcon,
        AssetDocuments,
        AssetList,
        HeatmapIcon,
        IconButton,
        ListItem,
        PencilIcon,
        PinLocationIcon,
        RemoveIcon,
        TimeClockFileSearch,
        TrashBinIcon,
    },
    mixins: [CustomSocketHelper],
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            assetsCurrent: [],
            search: '',
        }
    },
    computed: {
        ...mapState('location', ['locations']),
        ...mapState('tracker', ['trackers']),
        ...mapGetters('auth', [
            'hasActivityAccess',
            'hasLocationHistoryAccess',
            'isSuperuser',
        ]),
        assetDetailLinkBase() {
            return `/map/location/${this.id}`
        },
        detachedPanelTitle() {
            return this.$t(`router.${this.$route.name}`)
        },
        location() {
            return this.locations.find(location => location.id == this.id)
        },
        assets() {
            // assets need to be sorted by decreasing id to detect changes in AssetList component
            return this.trackers
                .filter(tracker => {
                    return (
                        tracker.location === this.location.id &&
                        !this.assetsCurrent.find(({ id }) => tracker.id === id)
                    )
                })
                .sort((a, b) => b.id - a.id)
        },
        isChartViewActive() {
            return this.$route.meta?.isChart
        },
        userHasEditRights() {
            return this.location?.permissions?.access === 'WRITE'
        },
    },
    watch: {
        location: {
            immediate: true,
            async handler() {
                if (this.location) {
                    this.setActiveLocationOnMap(this.location)
                    this.assetsCurrent = await this.getLocationCurrentAssets()
                } else {
                    this.setActiveLocationOnMap(null)
                    this.assetsCurrent = []
                }
            },
        },
    },
    destroyed() {
        this.setActiveLocationOnMap(null)
    },
    sockets: {
        position(data) {
            const currentIndex = this.assetsCurrent.findIndex(
                ({ id }) => id === data.tracker
            )
            const isCurrent = currentIndex > -1
            const isInside = data.current_locations.includes(this.location?.id)

            if (isCurrent && !isInside) {
                this.assetsCurrent = [
                    ...this.assetsCurrent.slice(0, currentIndex),
                    ...this.assetsCurrent.slice(currentIndex + 1),
                ]
            } else if (!isCurrent && isInside) {
                const tracker = this.trackers.find(
                    ({ id }) => id === data.tracker
                )
                if (tracker) {
                    this.assetsCurrent.push(tracker)
                }
            }
        },
    },
    methods: {
        ...mapMutations('location', ['setActiveLocationOnMap']),
        async getLocationCurrentAssets() {
            const { id } = this.location
            const { data } = await httpHelper.get(`/locations/${id}/`)
            return data.current_assets.reduce((acc, cur) => {
                const tracker = this.trackers.find(({ asset }) => asset === cur)
                return tracker ? [...acc, tracker] : acc
            }, [])
        },
        handleLocationDelete() {
            this.$modal.show('dialog', {
                title: this.$t('confirmDelete'),
                buttons: [
                    {
                        title: this.$t('confirmOk'),
                        handler: async () => {
                            const { id } = this.location
                            await httpHelper.drop(`/locations/${id}/`)
                            await commonService.populateData()
                            this.$modal.hide('dialog')
                            this.$router.push('/map')
                        },
                    },
                    {
                        title: this.$t('shared.cancel'),
                        handler: () => {
                            this.$modal.hide('dialog')
                        },
                    },
                ],
            })
        },
    },
}
</script>

<i18n>
{
    "en": {
        "confirmDelete": "Delete the location irreversibly?",
        "confirmOk": "Delete location",
        "editLocation": "Edit location",
        "deleteLocation": "Delete location",
        "diagnostics": "Diagnostics",
        "connectionHeatmap": "Connection Heatmap",
        "showActivity": "Show activity"
    },
    "de": {
        "confirmDelete": "Soll der Standort unwiderruflich gelöscht werden?",
        "confirmOk": "Standort löschen",
        "editLocation": "Standort bearbeiten",
        "deleteLocation": "Standort löschen",
        "diagnostics": "Diagnose",
        "connectionHeatmap": "Verbindungsqualität",
        "showActivity": "Aktivität anzeigen"
    },
    "fr": {
        "confirmDelete": "Supprimer l'emplacement définitivement ?",
        "confirmOk": "Supprimer l'emplacement",
        "editLocation": "Editer l'emplacement",
        "deleteLocation": "Supprimer l'emplacement",
        "diagnostics": "Diagnostics",
        "connectionHeatmap": "Qualité de la connexion",
        "showActivity": "Afficher l'activité"
    },
    "it": {
        "confirmDelete": "Soll der Standort unwiderruflich gelöscht werden?",
        "confirmOk": "Eliminare Locazione",
        "editLocation": "Modificare Locazione",
        "deleteLocation": "Eliminare Locazione",
        "diagnostics": "Diagnostica",
        "connectionHeatmap": "Qualità della connessione",
        "showActivity": "Mostra attività"
    }
}
</i18n>

<style lang="scss" scoped>
.title-container {
    flex: 1;

    div {
        justify-content: space-between;
    }
}
</style>
