<template>
    <div>
        <ListHeader class="u-bb">
            <template slot="title">{{ $t('documents') }}</template>

            <template v-if="showEditActions" slot="accessory">
                <label>
                    <input type="file" multiple @change="handleFile" />

                    <IconButton no-button>
                        <AddIcon width="18" height="18" />
                    </IconButton>
                </label>
            </template>
        </ListHeader>

        <List :is-loading="isLoading">
            <ListItem v-for="(doc, i) in files" :key="i">
                <div class="l-inline l-gap-2 l-center-v l-spread l-full-width">
                    <a
                        class="l-inline l-gap-2 l-center-v "
                        style="flex-grow: 1; height: 100%;"
                        :href="doc.file_url || doc.file"
                        target="_blank"
                    >
                        <div>
                            <VSpinner
                                v-if="doc.uploading"
                                :size="24"
                                :speed="1"
                                line-fg-color="black"
                                line-bg-color="transparent"
                            />

                            <FileIcon v-else width="24" height="24" />
                        </div>

                        <div class="l-stack l-gap-1">
                            <p
                                class="document-title"
                                :class="{ 't-subtle': doc.uploading }"
                            >
                                {{ doc.name }}
                            </p>
                        </div>
                    </a>

                    <div
                        v-if="
                            showEditActions && !doc.readonly && !doc.uploading
                        "
                    >
                        <IconButton @click="deleteDocument(doc)">
                            <TrashBinIcon
                                width="18"
                                height="18"
                                name="remove"
                            />
                        </IconButton>
                    </div>
                </div>
            </ListItem>
        </List>
    </div>
</template>

<script>
import VSpinner from 'vue-simple-spinner'

import { httpHelper } from '@/utils'

import AddIcon from '@/components/icons/AddIcon'
import FileIcon from '@/components/icons/FileIcon'
import IconButton from '@/components/IconButton'
import List from '@/components/List'
import ListHeader from '@/components/ListHeader'
import ListItem from '@/components/ListItem'
import TrashBinIcon from '@/components/icons/TrashBinIcon'

export default {
    name: 'AssetDocuments',
    components: {
        AddIcon,
        FileIcon,
        IconButton,
        List,
        ListHeader,
        ListItem,
        TrashBinIcon,
        VSpinner,
    },
    props: {
        asset: {
            type: [String, Number],
            default: null,
        },
        location: {
            type: [String, Number],
            default: null,
        },
        showEditActions: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            files: [],
            isLoading: false,
        }
    },
    computed: {
        entity() {
            if (this.asset) {
                return 'asset'
            } else if (this.location) {
                return 'location'
            } else {
                return null
            }
        },
        entityId() {
            if (this.entity === 'asset') {
                return this.asset
            } else if (this.entity === 'location') {
                return this.location
            } else {
                return null
            }
        },
    },
    watch: {
        asset() {
            this.load()
        },
        location() {
            this.load()
        },
    },
    mounted() {
        this.load()
    },
    methods: {
        handleFile(event) {
            Array.from(event.target.files).forEach(this.uploadFile)
        },
        async uploadFile(file) {
            const newFile = { name: file.name, uploading: true }
            this.files.push(newFile)

            const formData = new FormData()
            formData.append('file', file)
            formData.append('name', file.name)
            formData.append(this.entity, this.entityId)

            const { data } = await httpHelper.post(
                `${this.entity}s/${this.entityId}/documents/`,
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                }
            )

            this.$set(this.files, this.files.indexOf(newFile), data)
        },
        async load() {
            this.isLoading = true
            let calls = []
            if (this.entity === 'asset') {
                calls.push(this.fetchDocumentation())
            }
            calls.push(this.fetchDocuments())

            let values = await Promise.all(calls)
            this.files = values.flat()
            this.isLoading = false
        },
        async fetchDocumentation() {
            const { data } = await httpHelper.get(
                `${this.entity}s/${this.entityId}/documentation`
            )
            return data.map(item => ({ ...item, readonly: true }))
        },
        async fetchDocuments() {
            const { data } = await httpHelper.get(
                `${this.entity}s/${this.entityId}/documents/`
            )
            return data.results
        },
        deleteDocument(document) {
            this.$modal.show('dialog', {
                title: this.$t('areyousure'),
                buttons: [
                    {
                        title: this.$t('yesdelete'),
                        handler: () => {
                            this.files.splice(this.files.indexOf(document), 1)
                            httpHelper.drop(
                                `${this.entity}s/${this.entityId}/documents/${document.id}`
                            )
                            this.$modal.hide('dialog')
                        },
                    },
                    {
                        title: this.$t('shared.cancel'),
                        handler: () => {
                            this.$modal.hide('dialog')
                        },
                    },
                ],
            })
        },
    },
}
</script>

<i18n>
{
    "en": {
        "documents": "Documents",
        "areyousure": "Are you sure?",
        "yesdelete": "yes, delete!"
    },
    "de": {
        "documents": "Dokumente",
        "areyousure": "Sind Sie sicher?",
        "yesdelete": "Ja, löschen!"
    },
    "fr": {
        "documents": "Documents",
        "areyousure": "Êtes-vous sûr ?",
        "yesdelete": "Oui, supprimer !"
    },
    "it": {
        "documents": "Documenti",
        "areyousure": "Sei sicuro?",
        "yesdelete": "sì, cancella!"
    }
}
</i18n>

<style lang="scss" scoped>
input[type='file'] {
    display: none;
}

svg {
    display: block;
}

.document-title {
    word-break: break-word;
}
</style>
